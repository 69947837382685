'use client'

import * as HoverCard from '@paladise/ui/components/ui/hover-card'
import { useLocale } from 'next-intl'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

export const linkClassName =
  'text-callout text-l-l1 after:bg-primary-p1 relative after:absolute after:bottom-[-6px] after:left-0 after:h-[2px] after:w-full after:transition-opacity data-[active=false]:after:opacity-0 data-[active=true]:after:opacity-100'

const isLinkActive = (pathname: string, locale: string, href: string) => {
  return (
    pathname === href || (pathname.replace(`/${locale}`, '') || '/') === href
  )
}

export const NavLink = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  const locale = useLocale()
  const pathname = usePathname()
  const isActive = isLinkActive(pathname, locale, href)

  return (
    // when active, show 2px height shadow on the bottom offset 6px
    <Link href={href} data-active={isActive} className={linkClassName}>
      {children}
    </Link>
  )
}

export const NavHoverLink = ({
  baseHref,
  subContents,
  children,
}: {
  baseHref: string
  subContents: { text: string; href: string }[]
  children: React.ReactNode
}) => {
  const locale = useLocale()
  const pathname = usePathname()
  const isActive =
    pathname.startsWith(baseHref) ||
    pathname.replace(`/${locale}`, '').startsWith(baseHref)

  return (
    <HoverCard.HoverCard openDelay={300}>
      <HoverCard.HoverCardTrigger
        data-active={isActive}
        className={`${linkClassName} cursor-default`}
      >
        {children}
      </HoverCard.HoverCardTrigger>
      <HoverCard.HoverCardContent
        className="isolate w-fit overflow-hidden p-0"
        align="start"
        sideOffset={8}
      >
        {subContents.map(({ text, href }) => (
          <Link
            key={href}
            href={href}
            className="border-glass-special-separators focus:bg-background-2nd text-quote data-[active=true]:text-button-p1 block w-full cursor-pointer select-none rounded-sm border-t px-3 py-2 first-of-type:border-0"
            data-active={isLinkActive(pathname, locale, href)}
          >
            {text}
          </Link>
        ))}
      </HoverCard.HoverCardContent>
    </HoverCard.HoverCard>
  )
}
